import { Heading, Text, List, ListItem, UnorderedList } from "@chakra-ui/react"

const MDXComponents = {
  h1: (props) => <Heading as="h1" fontSize="3xl" {...props} />,
  h2: (props) => <Heading as="h2" fontSize="2xl" {...props} />,
  h3: (props) => <Heading as="h3" fontSize="xl" {...props} />,
  p: (props) => <Text as="p" my={3} {...props} />,
  a: (props) => <Text as="a" textDecoration="underline" fontSize="xl" {...props} />,
  ul: (props) => <UnorderedList variant="" {...props} />,
  li: (props) => <ListItem {...props} />,
}

export default MDXComponents
