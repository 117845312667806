import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Button,
  Icon,
} from "@chakra-ui/react"
import Link from "next/link"
import React from "react"
import { SiSpotify } from "react-icons/si"
import { PlaylistModalMode } from "pages/_app"

export const PlaylistModal = ({
  mode,
  onClose,
}: {
  mode: PlaylistModalMode
  onClose: () => void
}) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null)

  return (
    <AlertDialog isOpen={!!mode} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        {mode === "scope" ? (
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Playlists <Badge colorScheme="green">Beta</Badge>
            </AlertDialogHeader>

            <AlertDialogBody>
              To use this feature, Spava needs access to create a playlist on Spotify.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} mr={3}>
                Go back
              </Button>
              <Link href={`/api/auth/spotify?redirectUrl=${window?.location?.pathname}`} passHref>
                <Button as="a" rel="nofollow" variant="solid" colorScheme="green">
                  <Icon as={SiSpotify} mr={2} /> Reconnect Spotify
                </Button>
              </Link>
            </AlertDialogFooter>
          </AlertDialogContent>
        ) : (
          <AlertDialogContent></AlertDialogContent>
        )}
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
